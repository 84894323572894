import React from "react";
import { motion } from "framer-motion";
import { Canvas } from "@react-three/fiber";
import { ArrowIcon } from "./utility-components";
import ThreeDeeSpinner from "../../elements/ThreeDeeSpinner";
import ThreeDeeImageSpinner from "../../elements/ThreeDeeImageSpinner";
import { useTranslation } from "react-i18next";

const ProjectCard = ({ project, onClick }) => {
  const { t } = useTranslation();
  
  const project3D = project.icon == null ? (
    <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
      <ThreeDeeSpinner
        index={project.id}
        totalModels={10}
        model={project.model}
        initialRotation={project.initialRotation}
      />
    </Canvas>
  ) : (
    <ThreeDeeImageSpinner
      imageSrc={project.icon}
      thickness={0.5}
      initialRotation={{ x: 0, y: 0, z: 0 }}
      autoRotate={true}
      scale={1}
    />
  );

  var opacity=0.4;
  if(project.enabled) opacity = 1;

  return (
    <motion.div
      className={`group relative bg-transparent overflow-hidden w-full mx-0 px-0 z-10 rounded-lg border border-transparent transition-all duration-300 ${project.enabled ? 'cursor-pointer' : 'opacity-50 pointer-events-none'}`}
      onClick={() => project.enabled && onClick(project)}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: opacity, y: 0 }}
    >
      <div className="absolute m-8 inset-0 bg-gradient-to-r from-blue-500/0 via-blue-500/20 to-blue-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur-xl" />
      <div className="relative h-56 w-full mx-auto">
        {project3D}
      </div>
      <div className="transform transition-all duration-300">
        <motion.h3 className="text-xl mx-auto text-center font-bold mb-2 text-white">
          {project.title}
        </motion.h3>
        <p className="text-sm text-center text-gray-300 mb-4">
          {project.description}
        </p>
        <div className="flex justify-center flex-wrap gap-2 mb-4">
          {project.technologies.slice(0, 2).map((tech) => (
            <motion.span
              key={tech}
              className="bg-blue-900 text-blue-200 px-2 py-1 rounded-full text-xs font-medium"
            >
              {tech}
            </motion.span>
          ))}
          {project.technologies.length > 2 && (
            <motion.span className="bg-blue-900 text-gray-200 px-2 py-1 rounded-full text-xs font-medium">
              +{project.technologies.length - 2}
            </motion.span>
          )}
        </div>
        <motion.div className="flex justify-center" whileHover={{ x: 5 }}>
          <span className="text-blue-500 dark:text-blue-400 inline-flex items-center">
            {t("openProject")}
            <motion.div>
              <ArrowIcon />
            </motion.div>
          </span>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ProjectCard;